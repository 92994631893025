(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("sortablejs"), require("inputmask"), require("nouislider"), require("bootstrap-slider"));
	else if(typeof define === 'function' && define.amd)
		define("surveyjs-widgets", ["sortablejs", "inputmask", "nouislider", "bootstrap-slider"], factory);
	else if(typeof exports === 'object')
		exports["surveyjs-widgets"] = factory(require("sortablejs"), require("inputmask"), require("nouislider"), require("bootstrap-slider"));
	else
		root["surveyjs-widgets"] = factory(root["Sortable"], root["Inputmask"], root["noUiSlider"], root["Slider"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__2__, __WEBPACK_EXTERNAL_MODULE__24__) {
return 